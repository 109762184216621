import './App.css';
import {FontSizes, FontWeights, Slider} from '@fluentui/react';
import { getTheme } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {Doughnut} from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);

function App() {

  const theme = getTheme();

  const [people, setPeople] = useState(5);
  const [documents, setdocuments] = useState(5);
  const [multipleSingees, setMultipleSignees] = useState(true);
  const [print, setPrint] = useState(true);
  const [scanned, setScanned] = useState(true);
  const [priority, setPriority] = useState(false);

  const [currentCosts, setCurrentCosts] = useState(0);
  const [costTegoly, setCostsTegoly] = useState(0);
  const [timeAll, setTimeAll] = useState(0);
  const [savings, setSavings] = useState(0);

  const [printCosts, setPrintCosts] = useState(0);
  const [scanCosts, setScanCosts] = useState(0);
  const [letterCosts, setLetterCosts] = useState(0);
  const [timeTegoly, setTimeTegoly] = useState(0);

  const data = {
    labels: ['Druckkosten', 'Scankosten', 'Briefkosten', 'Lohnkosten'],
    datasets: [{
      data: [printCosts, scanCosts, letterCosts, currentCosts - letterCosts],
      backgroundColor: ['#DB1F48', '#004369', '#E5DDC8', '#01949A'],
      hoverBackgroundColor: ['#DB1F48', '#004369', '#E5DDC8', '#01949A']
    }]
  };

  useEffect(() => {
    var allDocuments = people * documents;
    var wagesPerHour = 26;
    var time = 15;
    var addCosts = 0;
    var costPerLetter = 0.05;

    if(print){
      time += 3;
      //paperCost += 0.1 * allDocuments;
      setPrintCosts(0.1 * allDocuments * 12);
    } 
    if(scanned){
      time += 2;
      //paperCost += 0.2 * allDocuments;
      setScanCosts(0.2 * allDocuments * 12);
    } 
    addCosts += costPerLetter * allDocuments;
    //time += 3;
    if(!priority){
      addCosts += 1.6 * allDocuments;
    } else {
      addCosts += 11 * allDocuments;
    }
    setLetterCosts(addCosts * 12);
    if(multipleSingees) time += time * 0.4;
    var wagesCosts = (wagesPerHour / 60) * time;
    var sum = wagesCosts * allDocuments;
    //sum += paperCost * 12;
    sum += addCosts;
    setCurrentCosts(sum * 12);
    setCostsTegoly(people * 300);
    setTimeAll((time * allDocuments * 12) / 60);
    setTimeTegoly((5 * allDocuments * 12) / 60);
  }, [people, documents, multipleSingees, print, scanned, priority])

  useEffect(() => {
    setSavings(currentCosts - costTegoly);
  }, [currentCosts, costTegoly])
  return (
    <div className="App">
      <div style={{display:"flex", flexWrap:"wrap", justifyContent:"space-evenly"}}>

        <div style={{display:"flex", flexDirection:"column", boxShadow:theme.effects.elevation8}}>
          {/*<img src='tegoly-01.png' style={{maxWidth:"500px"}} alt='tegoly'/>*/}
          <div style={{flexGrow:1}}></div>
          <div style={{display:"flex", flexDirection:"column", gap:"20px", padding:"10px", backgroundColor:"#f1f4f9"}}>
          
          <div style={{fontSize:FontSizes.large, fontWeight: FontWeights.semibold}}>Wie viele Personen in Ihrem Unternehmen holen Unterschriften ein?</div>
          <Slider style={{fontSize: FontSizes.large}} label='' min={1} max={50} defaultValue={people} onChange={(num) => setPeople(num)} />
          
          <div style={{fontSize:FontSizes.large, fontWeight: FontWeights.semibold}}>Wie viele Dokumente schickt jeder Mitarbeiter zur Unterschrift<br/> (im Durchschnitt pro Monat, 10 Seiten je Dokument)?</div>
          <Slider label='' min={1} max={100} defaultValue={documents} onChange={(num) => setdocuments(num)}/>
          
          <hr style={{backgroundColor:"#316bac", width:"100%", height:"5px", border: "none"}} />
            
            <div style={{display:"flex", fontSize: FontSizes.large, fontWeight: FontWeights.bold, color:"#316bac"}}>
              <div>Kosten für den Papierprozess:</div>
              <div style={{flexGrow:1}}></div>
              <div>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(currentCosts + printCosts + scanCosts)}</div>
            </div>

            <div style={{display:"flex", fontSize: FontSizes.large, fontWeight: FontWeights.bold, color:"#316bac"}}>
              <div>Kosten tegolySIGN DATEV:</div>
              <div style={{flexGrow:1}}></div>
              <div>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(costTegoly)}</div>
            </div>

            <div style={{display:"flex", fontSize: FontSizes.size24, fontWeight: FontWeights.bold, color:"#316bac"}}>
              <div>Ersparnis mit tegolySIGN DATEV:</div>
              <div style={{flexGrow:1}}></div>
              <div>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(savings)}</div>
            </div>

            <hr style={{backgroundColor:"#316bac", width:"100%", height:"5px", border: "none"}} />

            <div style={{display:"flex", fontSize: FontSizes.large, fontWeight: FontWeights.semibold}}>
              <div>Kosten für den Papierprozess:</div>
              <div style={{flexGrow:1}}></div>
            </div>

            {/*<Doughnut data={data} options={{radius:"50%"}}/>*/}

            <div style={{fontSize:FontSizes.medium, paddingLeft: "3rem"}}>Drucken (sw): {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(printCosts)} (1 Cent pro Seite)</div>
            <div style={{fontSize:FontSizes.medium, paddingLeft: "3rem"}}>Scannen: {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(scanCosts)} (2 Cent pro Seite)</div>
            <div style={{fontSize:FontSizes.medium, paddingLeft: "3rem"}}>Porto: {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(letterCosts)} (1,65 € Porto Großbrief mit Umschlag)</div>
            <div style={{fontSize:FontSizes.medium, paddingLeft: "3rem"}}>Lohnkosten: {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(currentCosts - letterCosts)} (26 € Stundenlohn inkl. Lohnnebenkosten)</div>
            
            <div style={{display:"flex", fontSize: FontSizes.large, fontWeight: FontWeights.semibold}}>
              <div>Zeit für den Papierprozess: </div>
              <div style={{flexGrow:1}}></div>
              <div> {timeAll.toFixed(0)} Stunden</div>
              
            </div>
            <div style={{fontSize:FontSizes.medium, paddingLeft: "3rem"}}>(Zeit pro Dokument {(timeAll/(people*documents*12)*60).toFixed(0)} Min)</div>
            <div style={{display:"flex", fontSize: FontSizes.large, fontWeight: FontWeights.semibold}}>
              <div>Zeit für den digitalen Prozess: </div>
              <div style={{flexGrow:1}}></div>
              <div> {timeTegoly.toFixed(0)} Stunden</div>
            </div>
            <div style={{fontSize:FontSizes.medium, paddingLeft: "3rem"}}>(Zeit pro Dokument {(timeTegoly/(people*documents*12)*60).toFixed(0)} Min)</div>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
